<template>
  <div class="fitment-header">
    <van-nav-bar
      title="店铺装修"
      left-arrow
      @click-left="$router.back(-1)"
      fixed
      placeholder
    />
    <div style="padding: 2vw">
      <div>
        <div class="choose-box">
          <div>样式1</div>
          <van-button size="mini" class="shadow-btn" @click="choose('1')"
            >选择</van-button
          >
        </div>
        <header-small :value="content"></header-small>
      </div>

      <div style="height: 10px"></div>

      <div>
        <div class="choose-box">
          <div>样式2</div>
          <van-button
            v-if="!lock"
            size="mini"
            class="shadow-btn"
            @click="choose('2')"
            >选择</van-button
          >
          <van-button
            v-else
            plain
            size="mini"
            color="#FF5200"
            style="width: 56px"
            @click="unlock"
            >解锁</van-button
          >
        </div>
        <header-big :value="content"></header-big>
      </div>
    </div>
  </div>
</template>

<script>
import headerSmall from "@/components/fitment-box/header-small";
import headerBig from "@/components/fitment-box/header-big";
import { unlock } from "@/api/fitment";
export default {
  name: "fitmentHeader",
  components: {
    headerSmall,
    headerBig,
  },
  data() {
    return {
      content: {},
    };
  },
  created() {
    if (this.$store.state.fitment.headerContent.storeName) {
      this.content = this.$store.state.fitment.headerContent;
    }
  },
  computed: {
    lock() {
      return this.$store.state.fitment.modules.length
        ? this.$store.state.fitment.modules[7].lock
        : true;
    },
    gold() {
      return this.$store.state.fitment.modules.length
        ? this.$store.state.fitment.modules[7].cost
        : 0;
    },
  },
  methods: {
    // 选择
    choose(type) {
      this.$router.push({ path: "fitmentHeader", query: { type } });
    },
    // 解锁
    unlock() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否支付" + this.gold + "创客金币开通样式2？",
        })
        .then(() => {
          console.log("confirm");
          unlock({
            unlockType: "1", // 写死
            unlockId: "18",
            modulesType: "0",
            gold: this.gold,
          }).then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.$toast("解锁成功");
              this.$store.dispatch("getUnlockedConfigs").then((res) => {
                console.log(res);
              });
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.fitment-header {
  background-color: #f6f6f6;
  .choose-box {
    display: flex;
    justify-content: space-between;
    background-color: #eee;
    padding: 5px 16px;
    border-radius: 5px 5px 0 0;
  }
  .shadow-btn {
    box-shadow: 1px 1px 5px #e0e0e0;
    width: 56px;
  }
}
</style>